@import "../../styles/variables.scss";

.ant-table-content {
  overflow-x: auto;
}

// TODO - Need to fix the alignment once all the functionalities are working fine
.investor-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .investor-container {
    .investor-table-container {
      margin-top: 15px;
      .title-text {
        h1 {
          color: #3a87c7 !important;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
        }
      }
      .investor-table {
        display: flex;
        .table-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .table-data {
            width: 100%;
            .ant-table {
              background: inherit;
              .ant-table-cell {
                background: inherit;
                color: #ffffff;
                font-family: "DIN 2014", -apple-system, BlinkMacSystemFont,
                  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
                border-bottom: 2px solid $gray11;
                padding: 5px 10px 5px 10px;
                max-width: 100px;
              }

              .ant-table-cell-row-hover {
                color: white;
                background-color: $table-row-highlighting;
                .button-element {
                  visibility: visible;
                }
              }
              .ant-empty-description {
                color: #fff;
              }
              .ant-table-cell-scrollbar {
                box-shadow: none;
              }
              .ant-table-thead {
                .table-row-content {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
