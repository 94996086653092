@import "../../../../styles/variables.scss";

.unregulated-trust-form {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .sub-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
    .reg-sub-header {
      font-weight: 300;
      font-size: 17px;
      font-style: normal;
      margin-bottom: 1%;
      color: $white;
    }
    .ant-input::placeholder {
      color: #757575;
    }
    .register-input-large {
      background: $gray4;
      color: #939393;
      border: none;
      margin-bottom: 2%;
      padding: 2%;
      font-size: 17px;
    }
    .register-input-small {
      background: $gray4;
      color: #939393;
      border: none;
      margin-bottom: 2%;
      width: 63%;
      padding: 2%;
      font-size: 17px;
    }
    .reg-state-details {
      display: flex;
      justify-content: space-between;
      color: #939393;
    }
    .register-input-autocomplete {
      margin-bottom: 2%;
    }
    .ant-select-selector {
      width: 100%;
      height: 19px;
      background: inherit;
      border: none;
    }
    .ant-select-selection-item {
      line-height: 20px;
    }
    .register-select {
      background: $gray4;
      border: none;
      width: 35%;
      margin-bottom: 2%;
      color: #939393;
      font-size: 17px;
    }
    .register-select-large {
      background: $gray4;
      border: none;
      margin-bottom: 2%;
      padding: 2%;
      color: #939393;
      font-size: 17px;
      width: 350px;
    }
  }

  .reg-company-business-addr {
    .ant-checkbox-inner {
      background-color: $gray4;
      border: none;
    }
    .ant-checkbox-disabled + span {
      color: white;
      cursor: not-allowed;
    }
    .ant-checkbox-wrapper {
      color: white;
      margin-bottom: 2%;
      font-weight: 300;
      font-size: 15px;
      font-style: normal;
      align-items: baseline;
      line-height: 1.3;
      margin-left: 0px;
      width: fit-content;
    }
  }
}
