@import "../../styles/variables.scss";

.search-box {
  width: 205.86px;
  height: 39px;
  left: 930.37px;
  top: 185px;
  background: $gray4;
  color: #ffffff;
  flex: 1;
  margin-bottom: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  padding-left: 3px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 5px;
  outline: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .search-text {
    width: 80%;
    background: transparent;
    border: none;
    outline: none;
  }
}
