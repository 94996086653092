@import "../../../../styles/variables.scss";

.beneficiary-ownership-reg-main-content {
  display: flex;
  flex-direction: column;
  height: 473px;
  .prism-address-container {
    width: 98%;
  }
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .reg-sub-header {
    font-weight: 300;
    font-size: 17px;
    font-style: normal;
    margin-bottom: 3%;
    margin-left: 6%;
    margin-right: 6%;
    line-height: 1.3;
    color: white;
  }
  .reg-select-large {
    background: $gray4;
    border: none;
    margin-bottom: 2%;
    margin-left: 6%;
    margin-right: 6%;
    padding: 2%;
    color: #939393;
    width: 40%;
    font-size: 17px;
  }
  .beneficiary-individuals {
    margin-top: 5%;
    max-height: 45%;
    .individual-inputs {
      display: flex;
      flex-direction: column;
      max-height: 70%;
      overflow-y: auto;
      margin-bottom: 5px;
      .individual-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 6%;
        margin-right: 6%;
      }
      .individual-input {
        background: $gray4;
        border: none;
        margin-bottom: 2%;
        padding: 2%;
        color: #939393;
        width: 46%;
        &.input-medium {
          width: 35%;
          margin-right: 10px;
        }
        &.input-small {
          width: 30%;
        }
        &.ant-picker {
          &.ant-picker-focused {
            box-shadow: none;
          }
          .ant-picker-input > input {
            color: #939393;
            &::placeholder {
              color: #757575;
            }
          }
        }
      }
      .individual-role-list {
        display: flex;
        margin-right: 6%;
        margin-left: 6%;
        .individual-input-large {
          background: $gray4;
          border: none;
          margin-bottom: 2%;
          padding: 2%;
          color: #939393;
          width: 68%;
          margin-right: 12px;
        }
      }
      .individual-address {
        display: flex;
        margin-left: 6%;
        margin-right: 1%;
        .list-clear-btn {
          display: flex;
          align-self: flex-end;
          padding-bottom: 15px;
        }
      }
      .tax-resident-selection {
        display: flex;
        align-items: baseline;
        .resident-link {
          font-size: 17px;
          margin-bottom: 3%;
          color: $blue1;
          text-decoration: underline;
          background: none;
          border: none;
          &:hover,
          &:focus {
            text-decoration: none;
            cursor: pointer;
            outline: none;
          }
        }
      }
      .tax-resident {
        font-size: 14px;
        padding-left: 1%;
        width: 55%;
        margin-right: 3%;
        &.error-field {
          color: #757575;
        }
      }
    }
    .btn-box {
      margin-left: 6%;
      .ant-btn {
        background: $gray4;
        color: white;
        border: none;
      }
    }
  }
}

.ownership-modal {
  height: 300px !important;
  width: 454px !important;
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  .ant-modal-header {
    background: inherit;
    border-radius: 15px;
    border: none;
    .ant-modal-title {
      color: $widget-title;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ant-modal-content {
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-body {
      margin-top: 1%;
      color: $white;
      padding-top: 5px;
    }
    .ant-modal-close-x {
      color: $white;
    }
  }
}

.tax-resident-modal {
  height: 300px !important;
  width: 454px !important;
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  .ant-modal-content {
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-body {
      margin-top: 4%;
      color: white;
    }
    .ant-modal-close-x {
      width: 40px;
      line-height: 18px;
      color: $white;
    }
  }
}

a {
  text-decoration: underline;
}
