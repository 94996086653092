@import "../../../styles/variables.scss";

.view-uploads-container {
  .document-container {
    display: flex;
    flex-wrap: wrap;
    color: $white;
    font-size: 15px;
    overflow: auto;
    justify-content: center;
    margin-top: 50px;
    .heading {
      color: white;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }

    .list-items {
      padding: 10px;
      height: 500px;
      overflow-y: scroll;
      background: #2c3e50;
      border: 1px solid;
      .list-item {
        color: white;
        border-bottom: 1px solid grey;
        min-height: 40px;
        padding-top: 5px;
        padding-bottom: 5px;

        margin: 0;
        cursor: pointer;
        .file-name {
          word-break: break-all;
          margin: 0;
        }
      }
      .list-item:hover .download-icon {
        display: inline-block;
      }
      .download-icon {
        display: none;
        color: white;
      }
    }
  }
}
