@import "../../../../styles/variables.scss";

.subscription-reg-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }

  .reg-subscription-option {
    display: flex;
    flex-direction: column;
    margin-bottom: 7%;
    .ant-checkbox-disabled + span {
      color: white;
      cursor: not-allowed;
    }
    .ant-checkbox-wrapper {
      color: white;
      margin-bottom: 2%;
      font-weight: 300;
      font-size: 15px;
      font-style: normal;
      align-items: baseline;
      line-height: 1.3;
      margin-left: 0px;
    }
    .reg-terms-conditions {
      .terms {
        text-decoration: underline;
        a {
          color: #fff;
        }
      }
    }
  }
}
