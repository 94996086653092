@import "../../../../styles/variables.scss";

.verification-reg-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .reg-sub-header {
    font-weight: 300;
    font-size: 17px;
    font-style: normal;
    margin-bottom: 5%;
    color: white;
  }
  .verification-checklist-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    .reg-checkbox {
      display: none;
    }
    .psedo-verification-checklist-details {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      .psedo-box {
        width: 16px;
        height: 16px;
      }
      .checkbox-label {
        color: white;
        font-weight: 300;
        font-size: 15px;
        font-style: normal;
        line-height: 1.3;
        padding: 0px 10px;
      }
    }
  }

  .verification-file-upload-main {
    height: 300px;
    color: white;
    .ant-upload {
      border-radius: 4px;
      color: white;
      background: $gray4;
      border-color: $gray3;
      height: 160px;
      margin-bottom: 8px;
      .ant-upload-text {
        margin: 0 0 4px;
        color: white;
        font-size: 16px;
      }
    }
    .ant-upload-list {
      color: white;
      overflow: auto;
      height: 100px;
    }
    .ant-upload-list-item-name {
      color: white;
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: $gray4 !important;
    }
  }

  select {
    width: 98%;
    height: 39px;
    background: $gray4;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #939393;
    padding: 8px;
  }
  option {
    max-width: 100%;
    word-wrap: break-word;
    text-overflow: inherit;
    white-space: normal;
  }
}

.verification-procedure-market-modal {
  height: 300px !important;
  width: 500px !important;
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  .ant-modal-header {
    background: inherit;
    border-radius: 15px;
    border: none;
    .ant-modal-title {
      color: $widget-title;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ant-modal-content {
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-body {
      margin-top: 5%;
      color: $white;
      padding-top: 5px;
    }
    .ant-modal-close-x {
      color: $white;
    }
    .submit-button {
      background-color: $primary-button;
      color: $white;
      border: none;
    }
    .cancel-button {
      background-color: $secondary-button;
      color: $white;
      border: none;
    }
  }
  .document-select-modal {
    height: 145px !important;
  }

  .document-selction-main {
    select {
      background: inherit;
      color: #fff;
      width: 100%;
      padding: 5px;
      outline: none;
    }
  }
}
