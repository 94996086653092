@import "../../../../styles/variables.scss";

.individual-verification-reg-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .reg-sub-header {
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    margin-bottom: 3%;
    margin-right: 6%;
    line-height: 1.3;
    color: white;
  }
  .individual-verification-text {
    font-weight: 300;
    font-size: 15px;
    font-style: normal;
    margin-bottom: 1%;
    line-height: 1.3;
    color: white;
    justify-content: center;
    display: flex;
  }
  .individual-verfication-dotted-border {
    border-radius: 4px;
    color: white;
    border: 2px dashed #474c55;
    padding: 5px;
  }
  .verification-file-upload-main {
    height: 200px;
    color: white;
    .ant-upload {
      border-radius: 4px;
      color: white;
      background: $gray4;
      border-color: $gray3;
      height: 120px;
      margin-top: 11px;
      .ant-upload-text {
        margin: 0 0 4px;
        color: white;
        font-size: 16px;
      }
    }
    .ant-upload-list {
      color: white;
      overflow: auto;
      height: 100px;
    }
    .ant-upload-list-item-name {
      color: white;
    }
  }
  select {
    width: 98%;
    height: 39px;
    background: $gray4;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #939393;
    padding: 8px;
  }
  option {
    max-width: 100%;
    word-wrap: break-word;
    text-overflow: inherit;
    white-space: normal;
  }
}

.individual-procedure-modal {
  .ant-modal-body {
    padding-top: 20px !important;
  }
}

.ant-modal-footer {
  border-top: 0 !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 100px;
  .ant-btn {
    padding: 0px 62px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border: none;
    background: grey;
  }
  .ant-btn-primary {
    background: linear-gradient(270deg, #e8983f 0%, #ecb774 100%);
  }
}

.individual-procedure-modal {
  height: 300px !important;
  width: 600px !important;
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  .ant-modal-header {
    background: inherit;
    border-radius: 15px;
    border: none;
    .ant-modal-title {
      color: $widget-title;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ant-modal-content {
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-body {
      margin-top: 5%;
      color: $white;
      padding-top: 5px;
    }
    .ant-modal-close-x {
      color: $white;
    }
    .submit-button {
      background-color: $primary-button;
      color: $white;
      border: none;
    }
    .cancel-button {
      background-color: $secondary-button;
      color: $white;
      border: none;
    }
  }
  .document-select-modal {
    height: 145px !important;
  }

  .document-selction-main {
    select {
      background: inherit;
      color: #fff;
      width: 100%;
      padding: 5px;
      outline: none;
      option {
        color: $black;
      }
    }
  }
}
