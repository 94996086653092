@import "../../../../styles/variables.scss";

.beneficial-owner-facta-details {
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  min-width: 498px;
  min-height: 593px;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .facta-main-container {
    padding: 20px 0px;
    .reg-sub-header {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: $white;
    }
    .container-label {
      padding-top: 18px;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: $white;
      max-width: 400px;
    }
    .user-name-input {
      background: $gray4;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #939393;
      padding: 8px;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 20px;
      height: 39px;
    }
    .select {
      display: flex;
      justify-content: flex-start;
      padding: 19px 0;
      select {
        width: 151px;
        height: 39px;
        background: $gray4;
        outline: none;
        border: none;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #939393;
        padding: 8px;
      }
    }
  }
}
