@import "../../styles/variables.scss";

.sample-message-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: $white;
  .sample-message-body {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .sample-message-content {
      display: table;
      height: 50%;
      width: 33%;
      background: $gray10;
      box-shadow: $background-color-shadow;
      border-radius: 15px;
      padding: 5px;
      p {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

/*
 * Page responsive based on the screen size
 */

@media only screen and (max-device-width: 480px) {
  .sample-message-content {
    min-width: 90%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .sample-message-content {
    min-width: 75%;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 992px) {
  .sample-message-content {
    min-width: 60%;
  }
}
@media only screen and (min-device-width: 993px) and (max-device-width: 1180px) {
  .sample-message-content {
    min-width: 50%;
  }
}
