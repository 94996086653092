.actionIcons {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  float: right;
  &__drag {
    margin-right: 0.5rem;
    cursor: pointer;
  }
  &__close {
    cursor: pointer;
  }
}
