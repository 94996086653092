@import "../../../styles/variables.scss";

.user-description {
  width: 40%;
  .ant-descriptions-title {
    color: $table-column-header-text;
  }
  .ant-descriptions-item-label {
    color: $table-column-header-text;
    background-color: inherit;
  }
  .ant-descriptions-item-content {
    color: #ffffff;
  }
}

.register-title {
  color: $table-column-header-text;
  background-color: inherit;
  font-size: 16px;
  font-weight: bold;
}
.ant-collapse {
  background: inherit;
  color: white;
  margin-bottom: 15px;
  border: none;
  width: 60%;
  .ant-collapse-item {
    // border-bottom: 1px solid #d9d9d9;
    background: inherit;
    color: white;
    border: none;
    .ant-collapse-header {
      border-bottom: none;
      color: white;
      background-color: inherit;
    }
  }

  .ant-collapse-content {
    color: white;
    background-color: inherit;
    border-top: none;
    border-bottom: 1px solid #d9d9d9;
    .ant-collapse-content-box {
      padding: 5px;
    }
  }
}

.pdf-file-viewer {
  height: 500px;
  .file-viewer-download {
    text-align: center;
  }
}
