@import "../../../styles/variables.scss";

.user-details {
  display: flex;
  align-items: center;
  height: inherit;
  .name-text {
    padding-right: 12px;
    p {
      margin: 0px 5px;
    }
  }
  .user-logout {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .obo-user {
    color: $primary-button;
    padding: 5px;
    border-radius: 1.5rem;
  }
}
