@import "../../../../styles/variables.scss";

.investor-evidence-reg-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 4%;
  margin-right: 4%;

  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }

  .reg-sub-header {
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    margin-bottom: 3px;
    color: white;
  }

  .reg-sub-info {
    font-size: 14px;
    font-style: normal;
    margin-bottom: 2%;
    color: white;
    line-height: 1.4;
  }

  .reg-list-item {
    color: white;
  }

  .reg-list {
    margin-bottom: 2%;
    padding-left: 5px;
  }
  .ant-collapse {
    background: inherit;
    color: white;
    margin-bottom: 15px;
    border: none;
  }

  .ant-collapse-item {
    //border-bottom: 1px solid #d9d9d9;
    background: inherit;
    color: white;
    border: none;
    .ant-collapse-header {
      color: white;
      background-color: inherit;
    }
  }

  .ant-collapse-content {
    color: white;
    background-color: inherit;
    border: none;
    .ant-collapse-content-box {
      padding: 5px;
    }
  }

  .verification-file-upload-main {
    height: 220px;
    color: white;

    .ant-upload {
      border-radius: 4px;
      color: white;
      background: $gray4;
      border-color: $gray3;
      height: 100px;
      margin-bottom: 8px;

      .ant-upload-text {
        margin: 0 0 4px;
        color: white;
        font-size: 16px;
      }
    }

    .ant-upload-list {
      color: white;
      overflow: auto;
      height: 100px;
    }

    .ant-upload-list-item-name {
      color: white;
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: $gray4 !important;
    }
  }
}
