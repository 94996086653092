@import "../../../../styles/variables.scss";

.fbTax-main {
  padding: 15px 20px;
  .fb-tax-information-details {
    margin: 25px 0;
    .individuals-textbox {
      .family-name {
        display: flex;
        margin-right: 7%;
        .family-name-textbox,
        .given-name-textbox {
          background: $gray4;
          color: #939393;
          border: none;
          margin-bottom: 10px;
          width: 46%;
          height: 42px;
          padding: 2%;
          font-size: 17px;
          &:nth-child(2) {
            margin-left: 40px;
          }
        }
      }
    }
    .dynamic-form-details {
      display: flex;
      margin-right: 7%;
      .individual-role {
        margin-right: 10px;
        background: $gray4;
        color: #939393;
        border: none;
        margin-bottom: 10px;
        width: 300px;
        height: 42px;
        padding: 2%;
        font-size: 17px;
      }
      .dob-input {
        background: $gray4;
        border: none;
        margin-bottom: 2%;
        padding: 2%;
        color: #939393;
      }
      .ant-picker {
        height: 42px;
        &.ant-picker-focused {
          box-shadow: none;
        }
        .ant-picker-input > input {
          color: #939393;
          &::placeholder {
            color: #757575;
          }
        }
      }
    }
    .member-list-address {
      display: flex;
      margin-right: 1%;
      .remove-btn {
        margin: 10px 0 0 10px;
        padding-right: 5px;
      }
      .member-address-container {
        width: 100%;
      }
    }
  }
}
.fbTax-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin-bottom: 25px;
}

.fbinformation-header,
.fb-confirmation-text,
.tax-residency-rule,
.name-of-individuals {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
  padding-bottom: 20px;
}

.fbinformation-header {
  font-weight: bold;
  color: white;
}

.field-values-list {
  max-height: 124px;
  overflow: auto;
}

.fb-confirmation-dropdown {
  display: flex;
  padding-bottom: 13px;
  select {
    width: 150px;
    height: 42px;
    background: $gray4;
    outline: none;
    border: none;
    font-size: 18px;
    color: #939393;
    padding: 8px;
  }
}

.btn-box {
  margin-top: 10px;
  .ant-btn {
    background: $gray4;
    color: white;
    border: none;
  }
}
