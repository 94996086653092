@import "../../../styles/variables.scss";

.page-content-footer {
  flex-direction: row;
  padding: 20px 30px;
  border-top: 2px solid $gray11;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 25;
  .footer-content {
    justify-content: space-between;
    display: flex;
    align-items: center;
    .footer-column {
      flex-direction: column;
      display: flex;
      font-weight: 400;
      color: $white;
      a {
        text-decoration: none !important;
        color: $white !important;
      }
    }
  }
}
