@import "../../styles/variables.scss";

.country-content {
  display: flex;
  flex-direction: column;
  .tax-information {
    .question {
      padding-top: 12px;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      max-width: 400px;
    }
    .tax-resident-list {
      max-height: 200px;
      overflow-y: auto;
      padding-right: 8px;
    }
    .tax-resident {
      display: flex;
    }
    .country-input,
    .tin-input,
    .reason-list {
      margin-top: 7px;
    }
    .list-clear-btn {
      display: flex;
      padding-left: 10px;
      padding-top: 10px;
      display: none;
      &.show-btn {
        display: block;
      }
    }
    .tax-information-input {
      background: $gray4;
      outline: none;
      border: none;
      font-weight: 300;
      font-size: 18px;
      color: #939393;
      padding: 8px;
      width: 100%;
    }
    .tin {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .reason-list {
      display: flex;
      .reason-select {
        display: flex;
        justify-content: flex-start;
        margin-right: 10px;
        &.select-box-margin {
          margin-right: 0px;
        }
        select {
          width: 100%;
          height: 39px;
          background: $gray4;
          outline: none;
          border: none;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 21px;
          color: #939393;
          padding: 8px;
        }
      }
      .country-tax-add-button {
        .ant-btn {
          background: $gray4;
          color: white;
          border: none;
          height: 100%;
        }
      }
    }
  }
}
