@import "../../../../styles/variables.scss";

.RTTax-main {
  padding: 15px 20px;
}

.RTTax-title {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: $white;
  margin-bottom: 15px;
}

.RTTax-description {
  font-size: 17px;
  font-weight: normal;
  color: $gray8;
  margin-bottom: 15px;
}

.tax-exempt-status-dropdown {
  display: flex;
  padding-bottom: 20px;
  select {
    width: 350px;
    height: 42px;
    background: $gray4;
    outline: none;
    border: none;
    font-size: 18px;
    color: #939393;
    padding: 8px;
  }
}

.giin-input-large {
  background: $gray4;
  color: #939393;
  border: none;
  margin-bottom: 20px;
  width: 350px;
  height: 42px;
  padding: 2%;
  font-size: 17px;
}
