@import "../../styles/variables.scss";

.order-book-main-container {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .order-book-header {
    display: flex;
    align-items: center;
  }
  .order-book-title {
    color: $widget-title;
    font-weight: bold;
    margin-right: 10px;
  }
  .order-book-text {
    font-weight: 700;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  .order-book-input {
    display: flex;
    border: none;
    .order-type {
      background: $gray4;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      width: 200px;
      border: none;
    }
    .search-text {
      margin-left: 10px;
      background: $gray4;
      font-weight: 400;
      color: #ffffff;
      width: 200px;
      border: none;
      .ant-input {
        background: inherit;
        color: inherit;
      }
    }
  }
  .order-book-table-container {
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 5px;
    .order-book-table {
      display: flex;
      .table-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .table-data {
          .ant-table-thead > tr > th {
            border-bottom: none;
          }
          .ant-table-tbody {
            .ant-table-cell {
              border-top: 2px solid $gray11;
              border-bottom: none !important;
            }
          }
          width: 100%;
          .separator {
            border-left: 2px solid $gray11;
            padding: 0ch;
            width: 1px;
          }
          .dot {
            height: 12px;
            width: 12px;
            background-color: #5bb844;
            border-radius: 50%;
            display: inline-block;
          }
          .ant-table-sticky-holder {
            background-color: $gray10; // This is needed inorder to hide the scrolling content in the header in the background. Inherit is not working as expected. Using same color as the widget
          }
          .ant-table {
            background: inherit;
            .ant-table-cell {
              background: inherit;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              color: #ffffff;
              padding: 5px 5px;
              &.show-border {
                border-right: 2px solid $gray11;
              }
              @media screen and (max-width: 1270px) {
                font-size: 12px;
              }
              @media screen and (max-width: 1000px) {
                font-size: 11px;
              }
            }
            .ant-table-thead {
              background-color: $gray10;
              position: sticky;
              top: 0;
              z-index: 1;
              .headings {
                background: inherit;
                font-style: normal;
                font-weight: 500 !important;
                line-height: 16px !important;
                color: $table-column-header-text;
                text-align: center;
                @media screen and (max-width: 1270px) {
                  font-size: 14px;
                }
                @media screen and (max-width: 1000px) {
                  font-size: 12px;
                }
              }
              .headings-with-border {
                background: inherit;
                color: $table-column-header-text;
                font-weight: bold;
                line-height: 14px;
                border-right: 2px solid $gray11;
              }
              .centerAlign {
                text-align: center;
              }
            }
            .ant-table-tbody {
              .centerAlign {
                text-align: center;
              }
            }
            .ant-empty-description {
              color: $white;
            }
            .ant-table-cell-row-hover {
              background-color: $table-row-highlighting;
            }
          }
        }
      }
    }
  }
}
