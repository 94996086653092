@import "../../../styles/variables.scss";

.broker-detail-modal {
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  box-shadow: 8px 8px 8px 0px #00000040;
  width: 800px !important;
  padding-bottom: 0 !important;
  .ant-modal-header {
    background: none;
    border: none;
    padding: 12px 16px;
  }
  .ant-modal-title {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
  }
  .ant-modal-close {
    color: white;
  }
  .ant-modal-body {
    font-size: 14px;
    line-height: 2.5;
  }

  .ant-modal-content {
    border: none;
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .table-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;
      .table-data {
        width: 100%;
        .separator {
          border-left: 2px solid $gray11;
          padding: 0ch;
          width: 1px;
        }
        .ant-table {
          background: inherit;
          .ant-table-cell {
            background: inherit;
            color: #ffffff;
            line-height: 20px;
            border-bottom: 2px solid $gray11;
            padding: 5px 5px;
            cursor: pointer;
          }
          .ant-table-thead {
            background-color: $gray10;
            top: 0;
            z-index: 1;
            .headings {
              background: inherit;
              color: $table-column-header-text;
              font-weight: bold;
              line-height: 14px;
              font-size: 12px;
              text-align: center;
            }
            .ant-table-cell-scrollbar {
              box-shadow: none;
            }
          }
          .ant-table-cell-row-hover {
            color: white;
          }
          .ant-empty-description {
            color: #fff;
          }
          .ant-table-body {
            .ant-table-row {
              &.active-row {
                background-color: $gray4;
              }
              &.disabled-row {
                opacity: 0.5; /* Or any other styling to indicate disabled state */
                pointer-events: none; /* Disable click events on disabled rows */
              }
            }
          }
        }
      }
    }
    .obo-button-container {
      display: flex;
      justify-content: space-between;
      .obo-event-button {
        border-radius: 1.5rem;
        color: $white;
        margin-left: 10px;
        text-align: center;
        height: auto;
        &:hover,
        &:focus {
          color: $white;
          text-decoration: underline;
          outline: none;
        }
      }
      .obo-primary-button {
        background: $primary-button;
        border-color: $primary-button;
        &:hover,
        &:focus {
          background: $primary-button;
          border-color: $primary-button;
        }
      }
      .obo-secondary-button {
        background: $secondary-button;
        border-color: $secondary-button;
        &:hover,
        &:focus {
          background: $secondary-button;
          border-color: $secondary-button;
        }
      }
    }
  }
}
.participant-search-input {
  background: $gray4;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  height: 32px !important;
  color: #ffffff;
  flex: 1;
  margin-bottom: 6px;
  width: 40%;
  padding: 0 10px;
  border: none;
  &::placeholder {
    color: #939393;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-right-width: 1px !important;
    outline: 0;
  }
}
