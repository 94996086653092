@import "../../../../styles/variables.scss";

.national-credit-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .national-credit-text {
    font-weight: 300;
    font-size: 15px;
    font-style: normal;
    margin-bottom: 3%;
    line-height: 1.3;
    color: white;
  }

  .national-credit-option {
    display: flex;
    flex-direction: column;
    margin-bottom: 7%;
    .ant-checkbox-disabled + span {
      color: white;
      cursor: not-allowed;
    }
    .ant-checkbox-wrapper {
      color: white;
      margin-bottom: 2%;
      font-weight: 300;
      font-size: 15px;
      font-style: normal;
      align-items: baseline;
      line-height: 1.3;
      margin-left: 0px;
    }
  }
}
