@import "../../styles/variables.scss";

body {
  // background: $gray9;
}

.password-reset-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: $white;
  .password-reset-body {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .password-reset-content {
      height: 50%;
      width: 33%;
      background: $gray10;
      box-shadow: $background-color-shadow;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      .password-reset-title {
        margin: 2%;
        font-size: 16px;
      }
      .password-reset-main {
        border: none;
        display: flex;
        height: 96%;
        flex-direction: row;
        .password-reset-left {
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 85%;
          .password-reset-input {
            width: 90%;
            background: $gray4;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            border: none;
            margin: 2%;
            color: $gray7;
          }
          .password-reset-button {
            background: $primary-button;
            border: none;
            color: $white;
            font-size: 14px;
            width: 90%;
            margin: 2%;
            justify-content: center;
            align-items: center;
            //box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2), inset 3px -3px 6px rgba(139, 140, 144, 0.2), inset -3px -3px 6px rgba(191, 194, 200, 0.9), inset 3px 3px 8px rgba(139, 140, 144, 0.9);
            box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
              inset 3px -3px 6px rgba(139, 140, 144, 0.2),
              //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
              //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
            border-radius: 49px;
          }
        }
        .password-reset-right {
          width: 40%;
          display: flex;
          align-items: center;
          color: $gray8;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0em;
          text-align: center;
          margin: 8%;
          margin-top: 0%;
        }
      }
    }
    .password-reset-login {
      display: flex;
      width: 33%;
      margin-top: 1%;
      margin-left: 4%;
      p {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .password-reset-footer {
    height: 20%;
  }
}

.password-expired {
  p {
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: $white;
  }
}

/*
 * Page responsive based on the screen size
 */

@media only screen and (max-device-width: 480px) {
  .password-reset-content {
    min-width: 90%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .password-reset-content {
    min-width: 75%;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 992px) {
  .password-reset-content {
    min-width: 60%;
  }
}
@media only screen and (min-device-width: 993px) and (max-device-width: 1180px) {
  .password-reset-content {
    min-width: 50%;
  }
}
