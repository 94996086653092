@import "../../../styles/variables.scss";

.sell-Positions-modal {
  background: #474c55;
  box-shadow: $background-color-shadow;
  border-radius: 15px;
  top: 25%;
  box-shadow: 8px 8px 8px 0px #00000040;
  padding-bottom: 0;
  // height: 380px !important;
  .ant-modal-header {
    background: none;
    border: none;
    padding: 12px 16px;
  }
  .ant-modal-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
  }
  .ant-modal-close {
    color: white;
  }
  .ant-modal-body {
    font-size: 14px;
    line-height: 2.5;
    padding-top: 2px;
    .sell-container {
      height: 100%;
      .field-content {
        display: flex;
        color: #ffffff;
        .left-block {
          width: 60%;
          .sell-order {
            display: flex;
            .sell-ordertype {
              padding-right: 19px;
            }
          }
          .sell-input-terms {
            display: flex;
            .sell-label {
              margin-right: 25px;
            }
            .sell-space {
              margin-right: 5px;
            }
          }
        }
        .right-block {
          width: 40%;

          .sell-checkbox {
            color: #939393;
            margin-left: 0px;
          }
        }

        .consideration-type {
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
          width: 95%;
          .sell-label {
            margin-right: 20px;
          }
          select:required:invalid {
            color: #939393;
          }
          .expression-of-interest-select {
            background: $gray4;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            height: 32px !important;
            color: #ffffff;
            flex: 1;
            border: none;
            outline: 0;
            padding-left: 6px;

            &:focus {
              box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
            }
            :disabled {
              cursor: not-allowed;
            }
          }
        }

        .sell-input {
          background: $gray4;
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 4px;
          width: 95%;
          padding: 2px;
          border: none;
          line-height: 2;
          padding-left: 10px;
          .ant-select-selector {
            background: $gray4;
            padding-top: 0px;
            padding-bottom: 0px;
            border: none;
          }
        }
        .icon-display {
          background: $gray4;
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 4px;
          width: 15%;
          padding: 2px;
          border: none;
          line-height: 2;
          padding-left: 10px;
          align-items: center;
        }

        .sell-input-width-adjust {
          background: $gray4;
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 4px;
          width: 80%;
          padding: 2px;
          border: none;
          line-height: 2;
          padding-left: 10px;
          .ant-select-selector {
            background: $gray4;
            padding-top: 0px;
            padding-bottom: 0px;
            border: none;
          }
        }
        a {
          text-decoration: underline;
          color: #939393;
        }
      }
      .widget-footer {
        width: 100%;
        display: flex;
        justify-content: center;
        .place-sell {
          background: $primary-button;
          //box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2), inset 3px -3px 6px rgba(139, 140, 144, 0.2), inset -3px -3px 6px rgba(191, 194, 200, 0.9), inset 3px 3px 8px rgba(139, 140, 144, 0.9);
          box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
            inset 3px -3px 6px rgba(139, 140, 144, 0.2),
            //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
            //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
          border-radius: 49px;
          margin-top: 3%;
          width: 60%;
          color: white;
          border: none;
          margin-right: 10px;
        }
        .place-sell-disable {
          background: $secondary-button;
          //box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2), inset 3px -3px 6px rgba(139, 140, 144, 0.2), inset -3px -3px 6px rgba(191, 194, 200, 0.9), inset 3px 3px 8px rgba(139, 140, 144, 0.9);
          box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
            inset 3px -3px 6px rgba(139, 140, 144, 0.2),
            //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
            //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
          border-radius: 49px;
          margin-top: 3%;
          width: 60%;
          color: white;
          border: none;
          margin-right: 10px;
        }
        .clear-sell {
          background: $secondary-button;
          box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
            inset 3px -3px 6px rgba(139, 140, 144, 0.2),
            inset -3px -3px 6px rgba(191, 194, 200, 0.9),
            inset 3px 3px 8px rgba(139, 140, 144, 0.9);
          border-radius: 49px;
          margin-top: 3%;
          width: 40%;
          color: white;
          border: none;
          margin-right: 10px;
        }
      }
    }
  }
  .ant-modal-content {
    border: none;
    background: none;
    box-shadow: none;
  }
  .ok-button {
    background: $primary-button;
    //box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2), inset 3px -3px 6px rgba(139, 140, 144, 0.2), inset -3px -3px 6px rgba(191, 194, 200, 0.9), inset 3px 3px 8px rgba(139, 140, 144, 0.9);
    box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
      inset 3px -3px 6px rgba(139, 140, 144, 0.2),
      //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
      //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
    border-radius: 49px;
    margin-top: 3%;
    width: 60%;
    color: white;
    border: none;
    margin-right: 10px;
  }
  .cancel-button {
    background: $secondary-button;
    box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
      inset 3px -3px 6px rgba(139, 140, 144, 0.2),
      inset -3px -3px 6px rgba(191, 194, 200, 0.9),
      inset 3px 3px 8px rgba(139, 140, 144, 0.9);
    border-radius: 49px;
    margin-top: 3%;
    width: 40%;
    color: white;
    border: none;
    margin-right: 10px;
  }

  .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px;
    display: flex;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    background: inherit;
    color: white;
  }
  .showWarning {
    display: flex;
    justify-content: space-around;
  }
}
