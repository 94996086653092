@import "../../../styles/variables.scss";

.order-trail-modal {
  width: 1280px !important;
  height: 350px !important;
  background: $gray10;
  box-shadow: $background-color-shadow;
  border-radius: 15px;
  top: 25%;
  box-shadow: 8px 8px 8px 0px #00000040;
  .ant-modal-content {
    border: none;
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-header {
      background: none;
      border: none;
      padding: 12px 16px;
    }
    .ant-modal-title {
      color: $widget-title;
      font-weight: 500;
      font-size: 16px;
    }
    .ant-modal-close {
      color: $white;
    }
    .ant-modal-body {
      font-size: 14px;
      line-height: 2.5;
      padding: 8px;
      word-wrap: break-word;
    }
  }
}
.view-container {
  padding-top: 2px;
  padding-bottom: 1%;
  height: 100%;
  width: 100%;

  .view-table-container {
    height: 85%;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 1px;
    .view-table {
      display: flex;
      .table-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .table-data {
          width: 100%;
          .ant-table {
            background: inherit;
            .ant-table-cell {
              background: inherit;
              color: #ffffff;
              font-size: 11px;
              line-height: 13px;
              border-bottom: 1px solid $gray11;
              padding: 5px 9px 5px 9px;
            }
          }
        }
        .ant-table-thead {
          .ant-table-cell {
            color: $table-column-header-text !important;
          }
        }
        .ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }
}
