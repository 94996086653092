@import "../../../../styles/variables.scss";

.Regulated-Trust-Main {
  padding: 15px 20px;
  min-width: 510px;
  min-height: 550px;
}

.regulatedTrust-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  padding-bottom: 15px;
}

.registered-trust-label {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
  padding-bottom: 7px;
}

.regulated-trust-dropdown {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  select {
    width: 350px;
    height: 42px;
    background: $gray4;
    outline: none;
    border: none;
    font-size: 18px;
    color: #939393;
    padding: 8px;
  }
}

.register-input-large {
  background: $gray4;
  color: #939393;
  border: none;
  margin-bottom: 20px;
  width: 350px;
  height: 42px;
  padding: 2%;
  font-size: 17px;
}

.company-name {
  &::placeholder {
    color: $gray12;
  }
}
