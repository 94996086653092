@import "../../../../styles/variables.scss";

.settlement-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  padding-bottom: 15px;
}

.settlement-details,
.settlement-notes {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
  padding-bottom: 20px;
}

.settlement-input-bsb,
.settlement-input-accountNumber,
.settlement-input-bankName {
  background: $gray4;
  color: #939393;
  border: none;
  margin-bottom: 20px;
  width: 350px;
  height: 42px;
  padding: 2%;
  font-size: 17px;
}
.select {
  display: flex;
  padding-bottom: 20px;
  select {
    width: 95px;
    height: 39px;
    background: $gray4;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    color: #939393;
    padding: 5px;
  }
}
