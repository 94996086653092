@import "../../styles/variables.scss";

.sophisticated-document-container {
  .document-container {
    color: $white;
    font-size: 20px;
    overflow: auto;
    margin-bottom: 65px;
    padding-left: 40px;
    padding-top: 50px;
    @media screen and (max-width: 1270px) {
      margin-bottom: 60px;
    }
    @media screen and (max-width: 700px) {
      margin-bottom: 80px;
    }
    .sub-header {
      font-weight: bold;
    }
    li {
      padding-top: 10px;
      a {
        color: $white;
      }
    }
  }
}
