@import "../../../../styles/variables.scss";

.listing-regulatory-reg-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .reg-sub-header {
    font-weight: 300;
    font-size: 17px;
    font-style: normal;
    margin-bottom: 5%;
    color: white;
  }
  .reg-company-details,
  .reg-subsidiary,
  .reg-regulated {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

    .ant-checkbox {
      top: 0;
    }
    .ant-checkbox-wrapper {
      color: white;
      margin-bottom: 2%;
      font-weight: 300;
      font-size: 15px;
      font-style: normal;
      align-items: center;
      line-height: 1.3;
      width: fit-content;
    }
    .register-input-large {
      background: $gray4;
      border: none;
      margin-bottom: 2%;
      color: #939393;
      padding: 2%;
      font-size: 17px;
    }
  }
}
