@import "../../../../styles/variables.scss";

.trusteeIdentification-main {
  padding: 15px 20px;
  min-width: 510px;
  min-height: 550px;
}

.trusteeIdentification-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  padding-bottom: 15px;
}

.trusteeIdentification-label {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  padding-bottom: 7px;
}

.trusteeIdentification-dropdown {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  select {
    width: 350px;
    height: 42px;
    background: $gray4;
    outline: none;
    border: none;
    font-size: 18px;
    color: #939393;
    padding: 8px;
  }
}
