@import "../../styles/variables.scss";

.prism-address-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  .ant-input::placeholder {
    color: $gray12;
  }
  .address-input {
    background: $gray4;
    color: $gray7;
    border: none;
    padding: 10px;
    margin-bottom: 10px;
    font-size: inherit;
  }
  .suburban-input {
    width: 100%;
  }
  .state-container {
    display: flex;
    justify-content: space-between;
    .state-input {
      width: 30%;
      margin-right: 10px;
      margin-bottom: 0px;
      opacity: 1;
    }
    .code-input {
      width: 30%;
      margin-bottom: 0px;
    }
    .country-input {
      width: 30%;
      margin-bottom: 0px;
      margin-right: 10px;
      opacity: 1;
    }
  }
}
