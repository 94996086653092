@import "../../../../styles/variables.scss";

.foreignCorporateIdentification-main {
  padding: 15px 20px;
  min-width: 510px;
  min-height: 550px;
}

.foreignCorporateIdentification-title {
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  padding-bottom: 15px;
}

.foreignCorporateIdentification-label {
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  padding-bottom: 7px;
}

.foreignCorporateIdentification-dropdown {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  select {
    width: 100%;
    height: 42px;
    background: $gray4;
    outline: none;
    border: none;
    font-size: 17px;
    color: #939393;
    padding: 8px;
  }
}

.select {
  display: flex;
  padding-bottom: 20px;
  select {
    width: 95px;
    height: 39px;
    background: $gray4;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    color: #939393;
    padding: 5px;
  }
}

.foreignCorporateIdentification-registrarName {
  display: flex;
  padding-bottom: 20px;
  width: 450px;
  height: 39px;
  background: $gray4;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 250;
  font-size: 17px;
  color: #939393;
  padding: 5px;
  margin-left: 3%;
}

.foreignCorporateIdentification-registeredAddress {
  display: flex;
  width: 100%;
  background: $gray4;
  font-weight: 250;
  outline: none;
  border: none;
  font-size: 17px;
  color: #939393;
  margin-right: 1%;
  margin-bottom: 3%;
  .address-autocomplete {
    font-size: 17px;
    .ant-select-selection-placeholder {
      color: #939393;
    }
  }
}

.foreignCorporateIdentification-foreignCompanyName {
  display: flex;
  width: 480px;
  height: 40px;
  background: $gray4;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 250;
  font-size: 17px;
  color: #939393;
  padding: 5px;
  margin-bottom: 20px;
}
