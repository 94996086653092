@import "../../styles/variables.scss";

.register-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1240px;
  .register-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    z-index: 1001;
    overflow: hidden;
    .register-loader-inner {
      height: 50%;
      display: flex;
      align-items: center;
    }
  }
  .register-header {
    color: white;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $gray11;
    min-height: 71px;
    height: 9%;
    align-items: center;
    .otc-logo {
      background: $white;
      padding: 0.2%;
      margin-left: 1%;
    }
  }
  .register-main {
    display: flex;
    flex-direction: row;
    min-height: 80vh;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0;
    .register-main-content {
      color: $white;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      width: 615px;
      height: 392px;
      line-height: 75px;
      padding: 18px;
      text-align: center;
    }
  }
  .minus-icon {
    color: $white;
  }
  .calender-icon {
    color: $white;
  }
  .error-field {
    background: #140505 !important;
    border: 1px solid #eb1515 !important;
  }
  input,
  select {
    &:focus {
      outline: none;
    }
  }
}
