@import "../../../../styles/variables.scss";

.corporate-listing-title {
  text-align: center;
  font-weight: 700;
}

.corporate-listing-title,
.corporate-listing-subTitle {
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 15px;
}

.nameOfMarketRegime-input-large,
.aus-company-input-large,
.market-name-input-large,
.regulator-name-input-large,
.licensee-details-input-large {
  background: $gray4;
  color: #939393;
  border: none;
  margin-bottom: 20px;
  width: 420px;
  height: 42px;
  padding: 2%;
  font-size: 17px;
}

.public-listed-company-area,
.aus-listed-company-area,
.regulated-aus-area {
  .ant-input-disabled {
    background-color: $gray4;
  }
  .ant-checkbox-disabled + span {
    color: white;
    cursor: not-allowed;
  }
  .ant-checkbox-wrapper {
    color: white;
    margin-bottom: 2%;
    font-weight: 300;
    font-size: 15px;
    font-style: normal;
    align-items: baseline;
    line-height: 1.3;
    margin-left: 0px;
    width: fit-content;
  }
}
