@import "../../styles/variables.scss";

body {
  // background: $gray9;
}

.complete-signup-container {
  .reset-password-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .reset-password-activity {
      width: 30%;
      height: 52%;
      color: $white;
      background: $gray10;
      box-shadow: $background-color-shadow;
      border-radius: 15px;
      .reset-password {
        margin: 2%;
      }
      .reset-password-layout {
        display: flex;
        flex-direction: row;
        .reset-password-entry {
          width: 50%;
          .reset-password-input {
            background: $gray4;
            margin-left: 5%;
            margin-top: 5%;
            width: 85%;
            border: none;
            height: 27%;
            font-size: 14px;
            color: $gray7;
          }
        }

        .reset-password-content {
          width: 45%;
          margin-right: 7%;
          text-align: center;
          color: $gray8;
          p {
            margin-top: 20%;
          }
        }
      }

      .reset-password-button-layout {
        .reset-password-button {
          background: $primary-button;
          //box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2), inset 3px -3px 6px rgba(139, 140, 144, 0.2), inset -3px -3px 6px rgba(191, 194, 200, 0.9), inset 3px 3px 8px rgba(139, 140, 144, 0.9);
          box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
            inset 3px -3px 6px rgba(139, 140, 144, 0.2),
            //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
            //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
          border-radius: 49px;
          margin-top: 35%;
          margin-left: 5%;
          margin-bottom: 2%;
          width: 85%;
          color: $white;
          border: none;
        }
      }
    }
    .reset-password-resetPassword {
      margin-top: 3%;
      width: 27%;
      p {
        margin-left: 2%;
        text-decoration: underline;
        cursor: pointer;
        color: $white;
      }
    }
  }
}

/*
 * Page responsive based on the screen size
 */

@media only screen and (max-device-width: 480px) {
  .reset-password-activity {
    min-width: 90%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .reset-password-activity {
    min-width: 75%;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 992px) {
  .reset-password-activity {
    min-width: 60%;
  }
}
@media only screen and (min-device-width: 993px) and (max-device-width: 1180px) {
  .reset-password-activity {
    min-width: 50%;
  }
}
