/* color codes */

$white: #ffffff;
$black: #000000;
$gray1: #c0c1c6;
$gray2: #8b8e94;
$gray3: #474c55;
$gray4: #343741;
$gray5: #797d82;
$gray6: #5f6369;
$gray7: #939393;
$gray8: #c4c4c4;
$gray9: #3e434b;
$gray10: #4d515a;
//$gray10: #212225;
$gray11: #878686;
$gray12: #757575;
$gray13: #7b7b7d;
$gray14: #d9d9d9;
$blue1: #1890ff;
//$primary-button: #A5A7AC;
$primary-button: #e8983f;
$secondary-button: #707275;
$widget-title: #e8983f;
$table-column-header-text: #ffffff;
$table-row-highlighting: $gray13;
$header-menu-colour: #ffffff;
$header-filter-colour: #ffffff;
$background-color-shadow: -1px 1px 2px rgba(50, 53, 60, 0.2),
  1px -1px 2px rgba(50, 53, 60, 0.2), -1px -1px 2px rgba(74, 79, 90, 0.9),
  1px 1px 3px rgba(50, 53, 60, 0.9);
