@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/fonts/fonts.css";

body {
  margin: 0;
  font-family: "DIN 2014", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #474c55 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTypography-root {
  font-family: "DIN 2014", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #343741;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #343741;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #f0f0f0;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

a:hover {
  color: initial !important;
}

.ant-input.border-\[\#7b7b7d\] {
  border-color: #7b7b7d;
}

.ant-checkbox-inner {
  background: transparent !important;
}

.ant-error .ant-checkbox-inner {
  border-color: #ff4d4f;
}

.dark-tick .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #474c55 !important;
}

.ant-message .anticon {
  vertical-align: initial !important;
  position: initial !important;
}

.bg-cubic-bg-lhs {
  background-image: url("./assets/images/cubic-bg-lhs.png");
}
