@import "../../../styles/variables.scss";

.prism-menu-item {
  border-radius: 50px;
  border: 0.5px solid rgba(192, 193, 198, 0.3);
  background: linear-gradient(135deg, #eaeaea 0%, #fff 80%);
  box-shadow: 1px 1px 3px 0px rgba(243, 243, 243, 0.9),
    -1px -1px 2px 0px rgba(247, 247, 247, 0.9),
    1px -1px 2px 0px rgba(243, 243, 243, 0.2),
    -1px 1px 2px 0px rgba(243, 243, 243, 0.2);
  position: relative;
  svg {
    position: absolute;
    right: 0;
  }
}

.dark-menu-item {
  border-radius: 50px;
  border: 0.75px solid #797d82;
  background: linear-gradient(139deg, #474c55 0%, #343741 100%);
  position: relative;
  svg {
    position: absolute;
    right: 0;
  }
}

.currency-select {
  padding-left: 10px;
  right: -11px;
  outline: none !important;
  .ant-select-selector {
    padding: initial !important;
    padding-right: 5px !important;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    height: initial !important;
  }
  .ant-select-clear {
    background: transparent !important;
    top: calc(50% - 2px) !important;
    right: 8px !important;
  }
}

.eta-switch {
  &.ant-switch-checked {
    background-color: purple !important;
  }
}

.page-content-header {
  color: $white;
  display: flex;
  border-bottom: 2px solid $gray11;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  .left-content {
    display: flex;
    align-items: center;
    .otc-logo {
      padding: 1%;
      margin-left: 1%;
    }
    .env-label {
      line-height: 1;
      background: $primary-button;
      padding: 0.3rem 0.8rem;
      border-radius: 1.5rem;
      color: $white;
      font-size: 1.2rem;
      margin-left: 20px;
      text-align: center;
      font-weight: bold;
    }
  }
  .middle-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .header-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-menu-anchor {
        color: $header-menu-colour;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-decoration: none;
        padding: 0 20px;
        &.active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
      .header-menu-filter {
        color: $header-filter-colour;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-decoration: none;
        padding: 0 20px;
      }
      .selectMenu {
        display: flex;
        position: relative;
        .down-arrow {
          margin: 2px 0 0 3px;
          color: $header-menu-colour !important;
        }
        .dropdown-menus {
          display: flex;
          flex-direction: column;
          position: absolute;
          background-color: $gray10;
          width: 200px;
          top: 20px;
          z-index: 1;
          h3 {
            color: $header-filter-colour;
            margin: 5px 0px 5px 10px;
            font-size: inherit;
            &:hover {
              background: $header-menu-colour;
            }
          }
        }
      }
      .header-select {
        background: inherit;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $white;
        border: none;
        cursor: pointer;
      }
    }
    .currency-list-menu {
      padding-right: 0px !important;
    }
    .eta-type-switch {
      .eta-content {
        padding: 0 3px;
      }
      .ant-switch-checked {
        background-color: purple !important;
        border: none !important;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .obo-button {
    background: $primary-button;
    border-radius: 1.5rem;
    color: $white;
    font-size: 1rem;
    margin-left: 20px;
    text-align: center;
    font-weight: bold;
    border-color: $primary-button;
    height: auto;
    &:hover,
    &:focus {
      background: $primary-button;
      border-color: $primary-button;
      color: $white;
      text-decoration: underline;
      outline: none;
    }
  }
}

.currency-list-name-dropdown {
  min-width: 80px !important;
  text-align: center !important;
  background-color: $gray10 !important;
  color: $white !important;
  .ant-select-item-option {
    color: $white !important;
    &.ant-select-item-option-selected {
      color: $gray7 !important;
    }
    &.ant-select-item-option-active {
      background-color: $header-filter-colour !important;
      color: $gray7 !important;
    }
    &.ant-select-item-option-disabled {
      color: $gray7 !important;
    }
  }
}
