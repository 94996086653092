@import "../../styles/variables.scss";

//TODO to fix the Alignment in the files once the pages are working fine
.portfolio-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .portfolio-container {
    @media screen and (max-width: 1270px) {
      margin-bottom: 60px;
    }
    @media screen and (max-width: 700px) {
      margin-bottom: 80px;
    }
    .portfolio-text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
    }
    .portfolio-table-container {
      overflow-x: hidden;
      overflow-y: hidden;
      .portfolio-table-action {
        .download-table {
          cursor: pointer;
          float: right;
          &.download-disabled {
            cursor: not-allowed;
          }
        }
      }
      .portfolio-table {
        display: flex;
        .table-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .table-data {
            width: 100%;
            .separator {
              border-left: 2px solid $gray11;
              padding: 0ch;
              width: 1px;
            }
            .ant-table {
              background: inherit;
              .ant-table-cell {
                background: inherit;
                color: #ffffff;
                font-size: 14px;
                border-bottom: 2px solid $gray11;
                padding: 5px 10px 5px 10px;
                max-width: 100px;
              }
              .ant-table-thead {
                .headings {
                  background: inherit;
                  color: $table-column-header-text;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 14px;
                  max-width: 75px;
                  text-align: center;
                }
                .headings-with-border {
                  background: inherit;
                  color: $table-column-header-text;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 14px;
                  border-right: 2px solid $gray11;
                }
                .table-row-content {
                  text-align: center;
                }
              }
              .button-components {
                display: flex;
                width: 100%;
                justify-content: space-around;
              }
              .button-div {
                height: 80%;
                width: 20%;
                margin-right: 32%;
              }
              .button-element {
                visibility: hidden;
              }
              .redeem-button {
                margin-left: 5px;
              }
              .ant-btn-round {
                background-color: #436be7;
                width: 59px;
                height: 25px;
                font-size: 12px;
                padding-left: 1px;
                padding-right: 1px;
                padding-bottom: 23px;
                padding-top: 4px;
                border: none;
              }
              .ant-table-cell-row-hover {
                color: white;
                background-color: $table-row-highlighting;
                .button-element {
                  visibility: visible;
                }
              }
              .ant-empty-description {
                color: #fff;
              }
            }
          }
        }
      }
      .search-box {
        width: 205.86px;
        height: 39px;
        left: 930.37px;
        top: 185px;
        background: $gray4;
        color: #ffffff;
        flex: 1;
        margin-bottom: 15px;
        padding-top: 2px;
        padding-bottom: 2px;
        border: none;
        padding-left: 3px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 5px;
        outline: none;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .search-text {
          width: 80%;
          background: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
}
.quantity-to-redeem {
  .text-box {
    width: 50%;
    margin: 30px auto;
  }
  .redeem-buttons {
    width: 50%;
    margin: 0 auto;
    .button-element:nth-child(2) {
      margin-left: 50px;
    }
  }
  .redemption-content {
    color: #ffffff;
    margin-left: 43px;
  }
  .quantity-to-redeem-input {
    width: 100%;
  }
}
