/*
    CSS file to override the ANTD table. Common for all the tables used in the site
*/

.ant-table-tbody {
  .align-right {
    text-align: right;
  }
  .align-center {
    text-align: center;
  }
}
