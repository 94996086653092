@import "../../styles/variables.scss";

.pdf-registration-document-container {
  .document-container {
    display: flex;
    flex-wrap: wrap;
    color: $white;
    font-size: 15px;
    overflow: auto;
    justify-content: center;
    margin-bottom: 65px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-left: 40px;

    @media screen and (max-width: 1270px) {
      margin-bottom: 60px;
    }
    @media screen and (max-width: 700px) {
      margin-bottom: 80px;
    }
    .pdf-registration-content {
      padding-left: 10px;
      padding-top: 10px;
      p {
        margin-bottom: 3px;
        padding-bottom: 0;
      }
    }
    .pdf-registration-content-mail {
      padding-left: 10px;
    }
    .investing-entity-header {
      font-weight: bold;
      padding-left: 20px;
      font-size: 18px;
    }
    .document-list-reg-forms {
      padding-left: 40px;
    }
    .sub-header {
      font-weight: bold;
      font-size: 21px;
    }
    li {
      padding-top: 10px;
      a {
        color: $white;
      }
    }
  }
}
