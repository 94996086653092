@import "../../../../styles/variables.scss";

.trust-verification-title {
  text-align: center;
}

.trust-verification-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  padding-bottom: 15px;
}

.trust-verification-documents {
  .ant-checkbox-disabled + span {
    color: white;
    cursor: not-allowed;
  }
  .ant-checkbox-wrapper {
    color: white;
    margin-bottom: 2%;
    font-weight: 300;
    font-size: 15px;
    font-style: normal;
    align-items: baseline;
    line-height: 1.3;
    margin-left: 0px;
    width: fit-content;
    margin-bottom: 30px;
  }
}

.trust-verification-file-upload {
  color: white;
  margin-bottom: 15px;
  margin-top: 10px;
  .reg-sub-header {
    font-weight: bold;
    margin-bottom: 5px;
    color: $white;
  }
  .ant-upload {
    border-radius: 4px;
    color: white;
    background: $gray4;
    border-color: $gray3;
    height: 150px;
    .ant-upload-text {
      margin: 0 0 4px;
      color: white !important;
      font-size: 16px;
    }
  }
  .ant-upload-list {
    color: white;
    overflow: auto;
  }
  .ant-upload-list-item-name {
    color: white;
  }
  .ant-upload-hint {
    color: $gray14;
    padding: 5px;
    font-size: 12px;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: $gray4 !important;
  }
}

.trust-verification-procedure-modal {
  height: 300px !important;
  width: 500px !important;
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  .ant-modal-header {
    background: inherit;
    border-radius: 15px;
    border: none;
    .ant-modal-title {
      color: $widget-title;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ant-modal-content {
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-body {
      margin-top: 5%;
      color: $white;
      padding-top: 5px;
    }
    .ant-modal-close-x {
      color: $white;
    }
    .submit-button {
      background-color: $primary-button;
      color: $white;
      border: none;
    }
    .cancel-button {
      background-color: $secondary-button;
      color: $white;
      border: none;
    }
  }
  .document-select-modal {
    height: 145px !important;
  }

  .document-selction-main {
    select {
      background: inherit;
      color: #fff;
      width: 100%;
      outline: none;
      padding: 5px;
    }
  }
}
