@import "../../styles/variables.scss";

.open-orders-container {
  padding: 7px 12px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .ant-table-cell-row-hover {
    background-color: $table-row-highlighting !important;
    .open-orders-actions {
      visibility: visible;
    }
  }
  .open-orders-title {
    color: $widget-title;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .open-orders-text {
    font-style: normal;
    font-weight: bold;
    padding: 0.5rem 0;
    line-height: 18px;
    color: #ffffff;
  }
  .open-orders-table-container {
    overflow-x: auto;
    overflow-y: auto;
    padding-bottom: 5px;
    .open-orders-table {
      display: flex;
      .table-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .table-data {
          width: 100%;
          .separator {
            border-left: 2px solid $gray11;
            padding: 0ch;
            width: 1px;
          }
          .ant-table-sticky-holder {
            background-color: $gray10; // This is needed inorder to hide the scrolling content in the header in the background. Inherit is not working as expected. Using same color as the widget
          }
          .ant-table {
            background: inherit;
            .ant-table-cell {
              background: inherit;
              color: #ffffff;
              line-height: 13px;
              border-bottom: 2px solid $gray11;
              padding: 5px 5px;
              @media screen and (max-width: 1270px) {
                font-size: 12px;
              }
              @media screen and (max-width: 1000px) {
                font-size: 10px;
              }
            }
            .ant-table-thead {
              background-color: $gray10;
              position: sticky;
              top: 0;
              z-index: 1;
              .headings {
                background: inherit;
                color: $table-column-header-text;
                font-weight: bold;
                line-height: 14px;
                font-size: 12px;
                text-align: center;
              }
              .headings-with-border {
                background: inherit;
                color: $table-column-header-text;
                font-weight: bold;
                line-height: 14px;
                border-right: 2px solid $gray11;
              }
            }
            .button-components {
              display: flex;
              width: 100%;
              justify-content: space-around;
            }
            .button-div {
              height: 80%;
              width: 20%;
              margin-right: 32%;
            }
            .button-element {
              visibility: hidden;
            }

            .openOrder-status-menu {
              display: flex;
              border: none;
              visibility: hidden;
              option {
                background: inherit !important;
                color: black;
              }
              .status-option {
                background-color: inherit;
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                border: none;
              }
            }
            .ant-table-cell-row-hover {
              .openOrder-status-menu {
                visibility: visible;
              }
            }
            .ant-btn-round {
              background-color: #436be7;
              width: 59px;
              height: 25px;
              padding-left: 1px;
              padding-right: 1px;
              padding-bottom: 23px;
              padding-top: 4px;
              border: none;
            }
            .ant-table-cell-row-hover {
              color: white;
              .button-element {
                visibility: visible;
              }
            }
            .ant-empty-description {
              color: #fff;
            }
          }
        }
      }
    }
    .search-text {
      width: 205.86px;
      height: 39px;
      left: 930.37px;
      top: 185px;
      background: $gray4;
      color: #ffffff;
      flex: 1;
      margin-bottom: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
      border: none;
      padding-left: 3px;
      line-height: 14px;
    }
  }
}
