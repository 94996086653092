@import "../../../../styles/variables.scss";

.proprietary-directors-reg-main-content {
  display: flex;
  flex-direction: column;
  height: 473px;
  .prism-address-container {
    padding-right: 2%;
  }
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 700;
    font-size: 15px;
    font-style: normal;
    color: white;
  }
  .reg-sub-header {
    font-weight: 300;
    font-size: 15px;
    font-style: normal;
    margin-bottom: 1%;
    margin-left: 6%;
    color: white;
  }
  .content {
    display: flex;
    flex-direction: column;
    max-height: 70%;
    overflow-y: auto;
    .list-iterate {
      display: flex;
      flex-direction: column;
      .list-iterate-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 6%;
        margin-right: 6%;
        .input-small {
          background: $gray4;
          color: #939393;
          border: none;
          margin-bottom: 2%;
          padding: 2%;
          font-size: 17px;
          width: 29%;
        }
        .input-medium {
          background: $gray4;
          color: #939393;
          border: none;
          margin-bottom: 2%;
          padding: 2%;
          font-size: 17px;
          width: 49%;
        }
        .input-medium-large {
          background: $gray4;
          color: #939393;
          border: none;
          margin-bottom: 2%;
          padding: 2%;
          font-size: 17px;
          width: 69%;
        }
        .input-large {
          background: $gray4;
          color: #939393;
          border: none;
          margin-bottom: 2%;
          padding: 2%;
          font-size: 17px;
          width: 100%;
        }
      }
    }

    .director-input {
      background: $gray4;
      border: none;
      margin-bottom: 2%;
      padding: 2%;
      color: #939393;
      width: 35%;
      margin-right: 10px;
      &.input-small {
        width: 32%;
      }
      &.ant-picker {
        &.ant-picker-focused {
          box-shadow: none;
        }
        .ant-picker-input > input {
          color: #939393;
          &::placeholder {
            color: #757575;
          }
        }
      }
    }
    .director-address {
      margin-left: 6%;
      margin-right: 4%;
      position: relative;
      display: flex;
      .list-clear-btn {
        align-items: self-end;
        display: flex;
        padding-bottom: 15px;
      }
    }
  }
  .btn-box {
    margin-left: 6%;
    .ant-btn {
      background: $gray4;
      color: white;
      border: none;
    }
  }
}
