@import "../../../../styles/variables.scss";

.form {
  display: flex;
  flex-direction: column;
  min-height: 550px;
  .prism-address-container {
    font-size: 17px;
  }
  .ant-input::placeholder {
    color: $gray12;
  }
  .ant-picker-input > input {
    &::placeholder {
      color: $gray12;
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      color: #ffffff;
    }
  }
  .main {
    padding: 30px 10% 0;
    .block {
      .row {
        display: flex;
        .left {
          padding-right: 20px;
          .register-select-large {
            background: $gray4;
            border: none;
            color: #939393;
            font-size: 17px;
            height: 40px;
            width: 70px;
            padding: 2%;
          }
        }
      }
    }
    .label {
      font-weight: 300;
      font-size: 17px;
      font-style: normal;
      color: white;
      padding: 10px 0;
    }
    .register-input-small {
      background: $gray4;
      color: #939393;
      border: none;
      margin-bottom: 2%;
      width: 100%;
      padding: 2%;
      font-size: 17px;
      .ant-picker-input {
        input {
          color: #939393 !important;
        }
      }
    }
    .user-details-input {
      width: 48%;
      &.firstname-field {
        margin-right: 4%;
      }
    }
  }
}
