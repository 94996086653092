@import "../../../../styles/variables.scss";

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: $white;
}
.contentTop {
  padding: 2px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #c4c4c4;
  padding: 20px 0;
}
.register-trade-select {
  display: flex;
  justify-content: center;
  padding: 28px 0;
  select {
    width: 268px;
    height: 39px;
    background: $gray4;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #939393;
    padding: 8px;
  }
}
.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #c4c4c4;
  .information-title {
    font-weight: 700;
  }
  .information-content {
    margin-top: 20px;
  }
}
.register-trade-btn {
  display: flex;
  justify-content: center;
  padding: 10px;
  .begin {
    padding: 12px 98px;
    background: $primary-button;
    border-radius: 49px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
