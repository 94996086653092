@import "../../../../styles/variables.scss";

.chess-details {
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  min-width: 498px;
  min-height: 593px;
  .title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
  .container-inner {
    padding: 8px 0px;
    .question {
      padding-top: 18px;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      max-width: 400px;
    }
    .select {
      display: flex;
      justify-content: flex-start;
      padding: 30px 0 0 0;
      select {
        width: 100%;
        height: 39px;
        background: $gray4;
        outline: none;
        border: none;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #939393;
        padding: 8px;
      }
    }
    .chess-details-input {
      background: $gray4;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #939393;
      padding: 8px;
      width: 100%;
      margin-top: 15px;
      height: 39px;
      &:disabled {
        cursor: not-allowed;
      }
    }
    .chess-details-file-upload {
      color: white;
      margin-bottom: 15px;
      margin-top: 10px;
      .reg-sub-header {
        font-weight: bold;
        margin-bottom: 5px;
        color: $white;
      }
      .ant-upload {
        border-radius: 4px;
        color: white;
        background: $gray4;
        border-color: $gray3;
        margin-top: 5px;
        padding: 20px 0px;
        .ant-upload-text {
          margin: 0 0 4px;
          color: white;
          font-size: 16px;
        }
      }
      .ant-upload-list {
        color: white;
        overflow: auto;
      }
      .ant-upload-list-item-name {
        color: white;
      }
      .ant-upload-hint {
        color: $gray14;
        padding: 5px;
        font-size: 12px;
      }
      .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: $gray4 !important;
      }
    }
  }
}
