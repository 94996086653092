@import "../../../../styles/variables.scss";

.foreign-corporate-details-main {
  padding: 15px 20px;
  .corporate-details-title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: $white;
    padding-bottom: 15px;
  }
  .corporate-details-content {
    margin: 20px 0px;
  }
  .corporate-details-description {
    font-size: 18px;
    color: $white;
    padding-bottom: 15px;
  }

  .corporate-details-input {
    background: $gray4;
    color: $gray7;
    border: none;
    margin-bottom: 20px;
    padding: 2%;
    font-size: 17px;
  }

  .agentName-input-large {
    width: 280px;
  }

  .row {
    display: flex;
    .left {
      padding-right: 20px;
      .company-type-select {
        background: $gray4;
        border: none;
        color: $gray7;
        font-size: 18px;
        height: 40px;
        width: 180px;
        padding: 2%;
        margin-bottom: 20px;
      }
    }
  }
  .prism-address-container .address-input {
    font-size: 18px;
  }
}
