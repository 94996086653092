@import "../../styles/variables.scss";

.address-autocomplete {
  width: 100%;
  &.error-field-autosuggest {
    .ant-select-selector {
      .ant-select-selection-search {
        background: #140505;
        border: 1px solid #eb1515;
      }
    }
  }
  .ant-select-selector {
    padding: 2% !important;
    border: none !important;
    color: #939393;
    height: auto !important;
    font-size: inherit;
    .ant-select-selection-search {
      left: 0;
      right: 0;
      background-color: $gray4;
      .ant-select-selection-search-input {
        padding-left: 2%;
        height: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #757575;
      z-index: 10;
    }
  }
  &.ant-select-disabled {
    .ant-select-selector {
      color: #939393 !important;
    }
  }
}

.address-autocomplete-dropdown {
  .ant-select-item-option {
    padding: 10px;
    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $gray4 !important;
      color: #fff;
    }
  }
}
