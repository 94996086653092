@import "../../../../styles/variables.scss";

.country-of-tax-residency {
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  min-width: 498px;
  min-height: 593px;
  .title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
  .container-inner {
    padding: 20px 0px;
    .sub-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
    }
    .tax-rules {
      color: #3a87c7;
      text-decoration: underline;
      margin: 20px 0 10px 0;
      font-size: 18px;
      cursor: pointer;
    }
    .question {
      padding-top: 18px;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      max-width: 400px;
    }
    .select {
      display: flex;
      justify-content: flex-start;
      padding: 19px 0;
      select {
        width: 151px;
        height: 39px;
        background: $gray4;
        outline: none;
        border: none;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #939393;
        padding: 8px;
      }
    }
  }
}

.view-details-modal {
  height: 300px !important;
  width: 454px !important;
  background: $gray10;
  border-radius: 15px;
  top: 25%;
  .ant-modal-content {
    background: inherit;
    box-shadow: none;
    border-radius: 15px;
    .ant-modal-body {
      margin-top: 4%;
      color: white;
    }
    .ant-modal-close-x {
      width: 40px;
      line-height: 18px;
      color: $white;
    }
  }
}

.countryOfTaxResidency-tfn {
  display: flex;
  padding-bottom: 20px;
  width: 450px;
  height: 39px;
  background: $gray4;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 250;
  font-size: 17px;
  color: #939393;
  padding: 5px;
  margin-left: 3%;
}
