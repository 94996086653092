@import "../../../../styles/variables.scss";

.fiTax-main {
  padding: 15px 20px;
  .facta-notification-clause {
    font-size: 18px;
    line-height: 21px;
    color: #c4c4c4;
  }
}

.fiTax-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  padding-bottom: 15px;
}

.fiTax-contentTop,
.tax-status-dropdown-label,
.giin-label,
.fatca-status-label {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
  padding-bottom: 20px;
}

.tax-status-dropdown,
.fatca-status-dropdown {
  display: flex;
  padding-bottom: 20px;
  select {
    width: 350px;
    height: 42px;
    background: $gray4;
    outline: none;
    border: none;
    font-size: 18px;
    color: #939393;
    padding: 8px;
  }
}

.giin-input-large,
.fatcaStatus-input-large {
  background: $gray4;
  color: #939393;
  border: none;
  margin-bottom: 20px;
  width: 350px;
  height: 42px;
  padding: 2%;
  font-size: 17px;
}
