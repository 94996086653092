@import "../../styles/variables.scss";

.login-box-shadow {
  box-shadow: inset 0px 1px 1px 0px rgba(137, 136, 136, 0.7);
}

.dark-ant-input {
  background: linear-gradient(
      242.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000 !important;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: white !important;
  border-color: #383c40 !important;
  input {
    background: linear-gradient(
        242.97deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #000 !important;
    color: white !important;
    padding-left: 10px !important;
  }
  .ant-input-password-icon {
    color: white !important;
  }
}

.login-container {
  height: 100vh;
  .login-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login-activity {
      display: flex;
      flex-direction: column;
      width: 30%;
      height: 50%;
      color: $white;
      background: $gray10;
      box-shadow: $background-color-shadow;
      border-radius: 15px;
      .login {
        margin: 2%;
      }
      .login-layout {
        display: flex;
        flex-direction: row;
        .login-entry {
          height: 95%;
          display: flex;
          flex-direction: column;
          width: 55%;
          .login-input {
            background: $gray4;
            margin-left: 5%;
            margin-top: 5%;
            width: 75%;
            border: none;
            height: 30%;
            font-size: 14px;
            color: $gray7;
          }
        }
        .login-content {
          width: 45%;
          margin-right: 7%;
          text-align: center;
          display: flex;
          flex-direction: column;
          color: $gray8;
        }
      }

      .login-button-layout {
        .login-button {
          background: $primary-button;
          margin-top: 5%;
          margin-left: 5%;
          margin-bottom: 2%;
          width: 75%;
          color: $white;
          box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
            inset 3px -3px 6px rgba(139, 140, 144, 0.2),
            //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
            //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
          border-radius: 49px;
          border: none;
        }
      }
    }
    .login-resetPassword {
      margin-top: 2%;
      width: 27%;
      display: flex;
      p {
        margin-left: 2%;
        text-decoration: underline;
        cursor: pointer;
        color: $white;
      }
      p:nth-child(2) {
        margin-left: 50%;
      }
    }
  }
}

/*
 * Page responsive based on the screen size
 */

@media only screen and (max-device-width: 480px) {
  .login-activity {
    min-width: 90%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .login-activity {
    min-width: 75%;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 992px) {
  .login-activity {
    min-width: 60%;
  }
}
@media only screen and (min-device-width: 993px) and (max-device-width: 1180px) {
  .login-activity {
    min-width: 50%;
  }
}
