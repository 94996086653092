@import "../../../../styles/variables.scss";

.unregulated-verification-main-content {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  .reg-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: white;
  }
  .reg-sub-header {
    font-weight: 300;
    font-size: 17px;
    font-style: normal;
    margin-bottom: 5%;
    color: white;
  }
  .unregulated-verification-checklist {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    .reg-checkbox {
      display: none;
    }
    .unregulated-verification-checklist-details {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      .checkbox-icon {
        width: 16px;
        height: 16px;
      }
      .checkbox-label {
        color: white;
        font-weight: 300;
        font-size: 15px;
        font-style: normal;
        line-height: 1.3;
        padding: 0px 10px;
      }
    }
  }

  .unregulated-verification-file-upload-main {
    color: white;
    .ant-upload {
      border-radius: 4px;
      color: white;
      background: $gray4;
      border-color: $gray3;
      padding: 30px 0px;
      .ant-upload-text {
        margin: 0 0 4px;
        color: white;
        font-size: 16px;
      }
    }
    .ant-upload-list {
      color: white;
      overflow: auto;
      height: 100px;
    }
    .ant-upload-list-item-name {
      color: white;
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: $gray4 !important;
    }
  }
}
