@import "../../styles/variables.scss";

.form-wizard-container {
  background: $gray10;
  box-shadow: -1px 1px 2px rgba(50, 53, 60, 0.2),
    1px -1px 2px rgba(50, 53, 60, 0.2), -1px -1px 2px rgba(74, 79, 90, 0.9),
    1px 1px 3px rgba(50, 53, 60, 0.9);
  border-radius: 15px;
  .form-wizard-container-inner {
    padding: 16px;
    display: flex;
    flex-direction: column;
    max-width: 554px;
    min-width: 480px;
    .form-wizard-footer {
      display: flex;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 10px;
      padding-bottom: 10px;
      .form-wizard-btn-prev {
        background: $secondary-button;
        box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
          inset 3px -3px 6px rgba(139, 140, 144, 0.2),
          inset -3px -3px 6px rgba(191, 194, 200, 0.9),
          inset 3px 3px 8px rgba(139, 140, 144, 0.9);
        border-radius: 49px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border: none;
        padding: 14px 62px;
      }
      .form-wizard-btn-next {
        padding: 14px 62px;
        background: $primary-button;
        //box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2), inset 3px -3px 6px rgba(139, 140, 144, 0.2), inset -3px -3px 6px rgba(191, 194, 200, 0.9), inset 3px 3px 8px rgba(139, 140, 144, 0.9);
        box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
          inset 3px -3px 6px rgba(139, 140, 144, 0.2),
          //inset -3px -3px 6px rgba(191, 194, 200, 0.9),
          //inset 3px 3px 8px rgba(139, 140, 144, 0.9)
;
        border-radius: 49px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border: none;
        &:disabled {
          cursor: not-allowed;
        }
        margin-left: 10px;
      }
      .form-wizard-btn-next-error {
        padding: 14px 62px;
        background: $secondary-button;
        box-shadow: inset -3px 3px 6px rgba(139, 140, 144, 0.2),
          inset 3px -3px 6px rgba(139, 140, 144, 0.2),
          inset -3px -3px 6px rgba(191, 194, 200, 0.9),
          inset 3px 3px 8px rgba(139, 140, 144, 0.9);
        border-radius: 49px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border: none;
        &:disabled {
          cursor: not-allowed;
        }
        margin-left: 10px;
      }
    }
  }
}

.register-modal-popup {
  .ant-modal-content {
    background: $gray10;
    box-shadow: $background-color-shadow;
    border-radius: 15px;
    color: #ffffff;
    .ant-modal-confirm-title {
      color: $white;
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        color: $white;
        border: none;
      }
      .submit-popup-btn {
        background: $primary-button;
      }
      .cancel-popup-btn {
        background: $secondary-button;
      }
    }
  }
}
