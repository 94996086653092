@import "../../../styles/variables.scss";

.upload-and-register-container {
  .document-container {
    display: flex;
    flex-wrap: wrap;
    color: $white;
    font-size: 15px;
    overflow: auto;
    justify-content: center;
    margin-top: 50px;
    .heading {
      color: white;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }

    .subheading {
      color: white;
      font-size: 18px;
    }

    .preview-container {
      color: $white;
      border: 1px solid #fff;
      height: 400px;
      width: 600px;
      padding-top: 15px;
      padding-left: 10px;
      padding-right: 10px;

      .list-items {
        padding: 10px;
        overflow-y: scroll;
        background: #2c3e50;
        height: 335px;
        .list-item {
          color: white;
          border-bottom: 1px solid grey;
          min-height: 40px;
          padding-top: 5px;
          padding-bottom: 5px;
          margin: 0;
          cursor: pointer;
          .file-name {
            word-break: break-all;
            margin: 0;
          }
        }
        .list-item:hover .delete-icon {
          display: inline-block;
        }
        .delete-icon {
          display: none;
          color: white;
        }
      }
    }
    .upload-container {
      height: 400px;
      width: 400px;
      background: rgb(240, 240, 240);
      border: 1px solid #fff;
      position: relative;
      .ant-upload-drag-icon {
        .upload-icon {
          color: #5b7ffc;
        }
      }
      .line-1 {
        font-size: 18px;
        font-weight: bold;
        color: #5b7ffc;
      }
      .line-2 {
        font-size: 14px;
        color: #808080;
      }
    }

    .action-button {
      margin-top: 20px;
      .download-forms {
        margin-right: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .left-arrow {
          margin-right: 10px;
        }
      }
      .submit-application {
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .right-arrow {
          margin-left: 10px;
        }
        .spinner {
          margin-left: 10px;
        }
        .ant-spin-dot-item {
          background-color: white;
        }
      }
    }
  }
}
.document-type-select-dialog {
  .ant-modal-content {
    .body {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      .text {
        text-align: center;
      }
      .select-input {
        margin-top: 10px;
        width: 350px;
      }
    }
  }
  .ant-modal-footer {
    margin: 0;
  }
}
